export type UsersActionsBlockProps = {
    searchInputValue: string;
    setSearchInputValue: React.Dispatch<React.SetStateAction<string>>;
    openNewUserModal: () => void;
};

export const testIds = {
    createUserButton: "create-user-button",
    userSearch: "user-search",
    mobileUserSearch: "mobile-user-search",
};
