import styled, { css } from "styled-components";

import { SortingOrder } from "./sorting-button.interface";

export const StyledContent = styled.div`
    display: flex;
    gap: 13px;
    align-items: center;
`;

export const StyledTitleAndOrderSvg = styled(StyledContent)`
    cursor: pointer;
`;

export const StyledTitle = styled.div`
    user-select: none;
    white-space: nowrap;
`;
export const StyledOrderBtn = styled.div<{ order: SortingOrder }>`
    user-select: none;
    min-width: 10px;
    ${({ order }) =>
        order === SortingOrder.ASC &&
        css`
            svg {
                transform: rotate(180deg);
            }
        `}
`;
