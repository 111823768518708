import { UserData } from "components/templates/users/users.interface";

export type ChangeUserRoleModalProps = {
    user?: UserData;
    opened: boolean;
    onClose: () => void;
};

export const testIds = {
    assignButton: "assign-button",
    cancelButton: "cancel-button",
    changeUserRoleModal: "change-user-role-modal",
    changeRoleErrorToast: "change-role-error-toast",
    changeRoleWarningToast: "change-role-warning-toast",
    changeRoleSuccessToast: "change-role-success-toast",
    subRoleNotFoundErrorToast: "sub-role-not-found-error-toast",
    userNotFoundErrorToast: "user-not-found-error-toast",
};

export const TOAST_SUCCESS_MESSAGE = "Role was changed.";
export const TOAST_SUCCESS_MESSAGE_KEY = "toast.successRoleChange";

export const SUB_ROLE_NOT_FOUND_KEY = "toast.subRoleNotFound";
export const SUB_ROLE_NOT_FOUND_MESSAGE = "Sub role not found.";
export const USER_NOT_FOUND_KEY = "toast.userNotFound";
export const USER_NOT_FOUND_MESSAGE = "User not found.";
