import styled from "styled-components";

import { colors } from "lib/palette";

export const StyledItemField = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: ${colors.black_1};
`;

export const StyledLabel = styled.p`
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;
