import { AuthService } from "lib/api/api";
import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { V1BlockResponse } from "../common/data-contracts";
import { HttpErrorType } from "../types";

type Options = UseMutationOptions<V1BlockResponse, HttpErrorType, string>;

export function useBlockUserMutation(options?: Options) {
    return useMutation(async (args) => {
        return (await AuthService.authServiceBlock(args, {})).data;
    }, options);
}
