export type ModalContentProps = {
    userId: string;
};

export const DISABLE_2FA =
    "If disable feature the user needs only email and password when log in. Disabling 2FA increases account vulnerability.";

export const testIds = {
    reset2faButton: "reset-2fa-button",
    change2faMandatoryStatusButton: "change-2fa-mandatory-status-button",
};

export const CHANGE_2FA_MANDATORY_STATUS_SUCCESS_TOAST_MESSAGE =
    "2FA mandatory status was successfully changed.";
export const CHANGE_2FA_MANDATORY_STATUS_SUCCESS_TOAST_KEY = "toast.disable2FA";
export const RESET_2FA_SUCCESS_TOAST_MESSAGE = "2FA was successfully reset.";
export const RESET_2FA_SUCCESS_TOAST_KEY = "toast.reset2FA";
