import styled, { css } from "styled-components";

import { colors } from "lib/palette";
import { breakpoints } from "lib/breakpoints";

export const StyledInputItem = styled.div`
    width: 100%;

    label {
        font-weight: 600;
        font-size: 13px;
        line-height: 20px;
        color: ${colors.black_1};
        margin-bottom: 10px;

        span {
            color: ${colors.red_1};
        }
    }
`;

export const StyledFirstRow = styled.div<{ withSubRole?: boolean }>`
    width: 100%;

    ${({ withSubRole }) =>
        withSubRole &&
        css`
            display: grid;
            gap: 15px;
            grid-template-columns: calc(50% - 7.5px) calc(50% - 7.5px);
        `};

    @media (max-width: ${`${breakpoints.sm}px`}) {
        grid-template-columns: 100%;
    }
`;

export const StyledSecondRow = styled.div`
    width: 100%;
    display: grid;
    gap: 15px;
    grid-template-columns: calc(50% - 7.5px) calc(50% - 7.5px);
    margin-top: 15px;

    @media (max-width: ${`${breakpoints.sm}px`}) {
        grid-template-columns: 100%;
    }
`;

export const StyledThirdRow = styled.div`
    width: 100%;
    display: flex;
    gap: 15px;
    margin-top: 30px;
`;

export const StyledContent = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const StyledForm = styled.form`
    width: 100%;
`;
