import styled from "styled-components";

import { colors } from "lib/palette";

export const StyledModalContent = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px 20px 30px;
`;

export const StyledRoleLabel = styled.p`
    font-weight: 600;
    font-size: 13px;
    line-height: 20px;
    color: ${colors.black_1};
    margin-bottom: 10px;
    margin-top: 15px;
`;
