import { useMemo } from "react";
import { keyBy } from "lodash";

import { CheckboxGroup } from "components/organisms/filter/checkbox-group/checkbox-group.component";
import { useGetRolesQuery } from "lib/api/hooks/use-get-roles-query.hook";
import {
    NOT_ASSIGNED_FILTER_OPTION,
    RoleFilterProps,
    testIds,
} from "./role-filter.interface";
import { FallbackQuery } from "components/organisms/fallback-query/fallback-query.component";

export const RoleFilter = ({ onChange, value, testId }: RoleFilterProps) => {
    const rolesQuery = useGetRolesQuery();

    const options = useMemo(
        () =>
            [NOT_ASSIGNED_FILTER_OPTION].concat(
                rolesQuery.data?.map((role) => ({
                    value: role.id,
                    label: role.title,
                })) || [],
            ),
        [rolesQuery.data],
    );

    const optionsMapById = useMemo(() => {
        return keyBy(options, "value");
    }, [options]);

    return (
        <FallbackQuery queries={[rolesQuery]}>
            <CheckboxGroup
                options={options}
                onChange={(values) =>
                    onChange(values.filter((id) => optionsMapById[id]))
                }
                value={value}
                testId={testId || testIds.roleFilter}
            />
        </FallbackQuery>
    );
};
