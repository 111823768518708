import styled from "styled-components";

import { breakpoints } from "lib/breakpoints";
import { colors } from "lib/palette";

export const StyledContent = styled.div`
    width: 100%;
    padding: 30px 20px 56px;
    display: flex;
    justify-content: center;

    @media (max-width: ${breakpoints.sm}px) {
        max-width: 100%;
    }
`;

export const StyledContainer = styled.div`
    width: 100%;
    max-width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
`;

export const StyledUserGeneralInfoBlock = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const StyledUserName = styled.h1`
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
    color: ${colors.black_1};
    margin-bottom: 4px;
    text-align: center;
`;

export const StyledRole = styled.p`
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    margin-bottom: 12px;
    color: ${colors.blue_2};
    text-align: center;
`;

export const StyledUserDataBlock = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`;

export const StyledRow = styled.div`
    display: flex;
    gap: 15px;
    max-width: 300px;
    border-top: 1px solid ${colors.blue_3};
    padding: 12px 0;
    width: 100%;

    &:last-child {
        border-bottom: 1px solid ${colors.blue_3};
    }
`;

export const StyledKey = styled.p`
    font-weight: 600;
    font-size: 13px;
    line-height: 20px;
    color: ${colors.black_1};
    min-width: 90px;
    max-width: 90px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;
export const StyledValue = styled.div`
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: ${colors.blue_2};
`;
