import { UserData } from "components/templates/users/users.interface";

export interface ModalContentProps {
    title: string;
    user: UserData;
    onUserRoleChange: (value: string) => void;
}

export const testIds = {
    chooseRoleDropdown: "choose-role-dropdown",
    option: "option",
};
