import styled from "styled-components";

import { breakpoints } from "lib/breakpoints";
import { colors, shadow } from "lib/palette";
import { zIndexes } from "lib/z-indexes";

export const StyledContent = styled.div`
    width: 100%;
    padding: 0 60px 60px;

    & > ul {
        padding-left: 0;
    }

    @media (max-width: ${`${breakpoints.sm}px`}) {
        padding: 0 0 60px;
    }
`;

export const StyledInfo = styled.div`
    margin-bottom: 20px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: ${colors.black_1};

    @media (max-width: ${`${breakpoints.sm}px`}) {
        margin-bottom: 30px;
    }
`;
export const StyledFooter = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 12px 0 12px 20px;
    width: 100%;
    border-top: 1px solid ${colors.blue_3};
    position: fixed;
    left: 0;
    bottom: 0;
    background: ${colors.white};
    z-index: ${zIndexes.footer};

    @media (max-width: ${`${breakpoints.sm}px`}) {
        bottom: 0;
    }

    &::before {
        content: "";
        display: inline-block;
        width: 100%;
        height: 10px;
        background: ${shadow.bottom};
        position: absolute;
        left: 0;
        top: -10px;
    }
`;

export const StyledLoaderBlock = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px;
`;
