export type FilterState = Record<"roles" | "status", string[]>;

export type UsersFilterProps = {
    onChange: (data: FilterState) => void;
    onCloseFilter: () => void;
    value: FilterState;
};

export const testIds = {
    roleFilter: "choose-roles-filter-button",
    statusFilter: "choose-status-filter-button",
    usersFilterLayout: "users-filter-layout",
    roleCheckbox: "roles-checkbox",
    statusCheckbox: "status-checkbox",
};
