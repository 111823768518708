export type CompanyAccessFormProps = {
    userId: string;
    onCancel: () => void;
    onSuccess: () => void;
    onError: () => void;
};

export const testIds = {
    companyAccessForm: "company-access-form",
    giveAccessButton: "give-access-button",
    cancelGiveAccessModalButton: "cancel-give-access-modal-button",
    loaderIcon: "loader-icon",
};
