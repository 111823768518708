export interface CreateNewUserModalProps {
    opened: boolean;
    onClose: () => void;
}

export const testIds = {
    createUserModal: "create-user-modal",
    createUserSuccessToast: "create-user-success-toast",
    createUserErrorToast: "create-user-error-toast",
    createUserModalBody: "create-user-modal-body",
    createUserModalSubmitButton: "create-user-modal-submit-button",
    createUserModalCancelButton: "create-user-modal-cancel-button",
};

export const CREATE_NEW_USER_SUCCESS_TOAST_MESSAGE_KEY =
    "toast.successCreateNewUser";
export const CREATE_NEW_USER_ERROR_TOAST_MESSAGE_KEY =
    "toast.errorCreateNewUser";
