export interface UserInfoProps {
    userId: string;
}

export const testIds = {
    userInfoBlock: "user-info-block",
    userName: "user-name",
    userRole: "user-role",
    userStatus: "user-status",
    userEmail: "user-email",
    userCreatedAt: "user-created-at",
    userLastEntrance: "user-last-entrance",
    userBannedAt: "user-banned-at",
};
