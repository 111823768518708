export enum SortingOrder {
    ASC = "asc",
    DESC = "desc",
}

export type SortingInfo = {
    name: string;
    order: SortingOrder;
};

export type SortingButtonProps = {
    name: string;
    filterBy: (data: SortingInfo) => void;
    info?: string;
    filterInfo: SortingInfo;
    label: string;
};

export const testIds = { sortingButton: "sorting-button" };
