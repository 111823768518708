import { useRef, useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { useRecoilValue } from "recoil";
import { Trans, useTranslation } from "next-i18next";

import { CreateUserForm } from "components/entities/users/organisms/create-user-form/create-user-form.component";
import { IFormState } from "lib/types/form";
import { StyledStepper } from "components/organisms/stepper/stepper.styled";
import { useGetUsersQueryKey } from "lib/api/hooks/use-get-users-query";
import { userState } from "lib/recoil/atoms/user.atom";
import { CompanyAccessForm } from "../create-new-user-second-step/company-access-form.component";
import { Icon } from "components/molecules/icon/icon.component";
import { Icons } from "components/molecules/icon/icon.interface";
import { errorToast, successToast } from "lib/utils/toast";
import { useGetUserAccessQueryKey } from "lib/api/hooks/use-get-user-access-query.hook";
import { ModalFooter } from "components/molecules/modal/components/modal-footer/modal-footer.component";
import { StyledStep } from "components/entities/users/organisms/create-new-user-modal/create-new-user-modal.styled";
import { V1CreateUserResponse } from "lib/api/common/data-contracts";
import { ModalDesktopHeader } from "components/molecules/modal/components/modal-desktop-header/modal-desktop-header.component";
import { useUserType } from "lib/hooks/use-user-type.hook";
import {
    testIds,
    CreateNewUserModalProps,
    CREATE_USER_ERROR_MESSAGE_KEY,
    CREATE_USER_SUCCESS_MESSAGE_KEY,
    UPDATE_USER_ACCESS_SUCCESS_MESSAGE_KEY,
    CREATE_USER_SUCCESS_MESSAGE,
    CREATE_USER_ERROR_MESSAGE,
    UPDATE_USER_ACCESS_SUCCESS_MESSAGE,
} from "./create-new-user-modal.interface";
import {
    StyledFormBlock,
    StyledMobileHeader,
    StyledStepContent,
    StyledStepTitle,
    StyledTitle,
    StyledModal,
    StyledLink,
} from "./create-new-user-modal.styled";

export const CreateNewUserModal = ({
    opened,
    onClose,
}: CreateNewUserModalProps) => {
    const { t } = useTranslation("users");
    const queryClient = useQueryClient();
    const authUserData = useRecoilValue(userState);
    const userType = useUserType();

    const submitRef = useRef<HTMLInputElement>(null);

    const [formState, setFormState] = useState<IFormState>({
        isReady: false,
        isLoading: false,
    });

    const [active, setActive] = useState(0);
    const [createdUserId, setCreatedUserId] = useState<string>();

    const onCloseHandler = () => {
        onClose();
        // reset stepper after full closing modal
        setTimeout(() => {
            setActive(0);
        }, 0);
    };

    const onCreateUserSuccessHandler = async (data?: V1CreateUserResponse) => {
        await queryClient.invalidateQueries([useGetUsersQueryKey]);
        setCreatedUserId(data?.user_id);
        setActive(1);
        successToast(
            t(CREATE_USER_SUCCESS_MESSAGE_KEY, CREATE_USER_SUCCESS_MESSAGE),
            {
                className: testIds.createUserSuccessToast,
            },
        );
    };

    const onCreateUserErrorHandler = () => {
        errorToast(
            t(CREATE_USER_ERROR_MESSAGE_KEY, CREATE_USER_ERROR_MESSAGE),
            { className: testIds.createUserErrorToast },
        );
    };

    const onSettingUpAccessesSuccessHandler = async () => {
        await queryClient.invalidateQueries([
            useGetUserAccessQueryKey,
            createdUserId,
        ]);
        onCloseHandler();
        successToast(
            t(
                UPDATE_USER_ACCESS_SUCCESS_MESSAGE_KEY,
                UPDATE_USER_ACCESS_SUCCESS_MESSAGE,
            ),
            {
                className: "access-granted",
            },
        );
    };

    const onSettingUpAccessesErrorHandler = async () => {
        errorToast(
            <Trans
                defaults={t(
                    "toast.errorAssignedAccessesWithLink",
                    "Error. The user accesses have not been added. You can add them under <anchor>Setting up accesses</anchor>.",
                )}
                components={{
                    anchor: (
                        <StyledLink
                            data-testid={testIds.updateUserAccessErrorToast}
                            href={`/users-permissions?userId=${createdUserId}`}
                        />
                    ),
                }}
            />,
            {
                autoClose: 15000,
            },
        );
    };

    if (!userType) {
        return null;
    }

    return (
        <StyledModal
            opened={opened}
            onClose={onCloseHandler}
            testId={testIds.createUserModal}
        >
            <ModalDesktopHeader
                title={t("title.createUser", "Create user")}
                onClose={onCloseHandler}
            />

            <StyledMobileHeader>
                <StyledTitle>
                    {t("title.createUser", "Create user")}
                </StyledTitle>
            </StyledMobileHeader>
            <StyledStepper
                size="xs"
                completedIcon={<Icon name={Icons.checkedStepIconSvg} />}
                active={active}
            >
                <StyledStep
                    data-testid={testIds.generalInformationStepButton}
                    label={t("label.generalInformation", "General information")}
                >
                    <StyledStepContent>
                        <StyledFormBlock>
                            <StyledStepTitle>
                                {t(
                                    "description.createUser",
                                    "Enter the e-mail of the user you want to add to your company and select the role from which he will participate in the system.",
                                )}
                            </StyledStepTitle>
                            <CreateUserForm
                                companyId={
                                    authUserData?.auth?.user?.b2b_company_id
                                }
                                withSubRole
                                onSuccess={onCreateUserSuccessHandler}
                                onError={onCreateUserErrorHandler}
                                setFormState={setFormState}
                                ref={submitRef}
                                userType={userType}
                            />
                        </StyledFormBlock>
                        <ModalFooter
                            firstButtonWidth={115}
                            secondButtonWidth={87}
                            firstButtonText={t(
                                "button.createUser",
                                "Create user",
                            )}
                            firstButtonTestId={
                                testIds.createUserModalSubmitButton
                            }
                            isFirstButtonDisabled={!formState.isReady}
                            isLoading={formState.isLoading}
                            onFirstButtonClick={() =>
                                submitRef.current?.click()
                            }
                            onSecondButtonClick={onCloseHandler}
                            showSecondButtonOnMobileOnly
                            secondButtonTestId={
                                testIds.createUserModalCancelButton
                            }
                        />
                    </StyledStepContent>
                </StyledStep>
                <StyledStep
                    label={t("label.accessToCompanies", "Access to companies")}
                >
                    <StyledStepContent>
                        {createdUserId && (
                            <CompanyAccessForm
                                onCancel={onCloseHandler}
                                userId={createdUserId}
                                onSuccess={onSettingUpAccessesSuccessHandler}
                                onError={onSettingUpAccessesErrorHandler}
                            />
                        )}
                    </StyledStepContent>
                </StyledStep>
            </StyledStepper>
        </StyledModal>
    );
};
