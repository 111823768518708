import { Checkbox } from "components/atoms/checkbox/checkbox.component";
import { CheckboxGroupProps } from "./checkbox-group.interface";
import { StyledItemField, StyledLabel } from "./checkbox-group.styled";

export const CheckboxGroup = ({
    onChange,
    options,
    value = [],
    testId,
}: CheckboxGroupProps) => {
    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.checked) {
            onChange([...value, e.target.value]);
        } else {
            onChange(value.filter((item) => item !== e.target.value));
        }
    };

    return (
        <>
            {options?.map((item) => {
                return (
                    <StyledItemField key={item.value}>
                        <Checkbox
                            onChange={handleOnChange}
                            checked={value.includes(item.value)}
                            value={item.value}
                            data-testid={`${testId}-${item.value}`}
                        />
                        <StyledLabel>{item.label}</StyledLabel>
                    </StyledItemField>
                );
            })}
        </>
    );
};
