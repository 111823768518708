import { useMediaQuery } from "@mantine/hooks";
import { Footer } from "components/atoms/footer/footer.component";
import { breakpoints } from "lib/breakpoints";

export const ModalMobileFooter = (props: any) => {
    const isMobile = useMediaQuery(`(max-width: ${`${breakpoints.sm}px`})`);

    if (!isMobile) {
        return null;
    }

    return <Footer {...props} />;
};
