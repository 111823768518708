import { Avatar } from "components/atoms/avatar/avatar.component";
import { UserStatusLabel } from "../../molecules/user-status-label/user-status-label.component";
import { UserCardProps } from "./user-card.interface";
import { NOT_ASSIGNED_SUB_ROLE_LABEL } from "lib/constants/not-assigned-sub-role";
import { colors } from "lib/palette";
import {
    StyledBlock,
    StyledInfoBlock,
    StyledRole,
    StyledText,
} from "./user-card.styled";
import { USER_DEFAULT_AVATAR } from "lib/constants/avatar";

export const UserCard = ({
    username,
    roleTitle,
    isBanned,
    isConfirmed,
    avatarImgUrl = USER_DEFAULT_AVATAR,
}: UserCardProps) => {
    return (
        <StyledBlock>
            <Avatar size={36} avatarImageUrl={avatarImgUrl} />
            <StyledInfoBlock>
                {/* <StyledUsername>{username}</StyledUsername> */}
                <StyledText color={colors.black_1}>{username}</StyledText>
                <StyledRole>
                    {roleTitle || NOT_ASSIGNED_SUB_ROLE_LABEL}
                </StyledRole>
            </StyledInfoBlock>
            <UserStatusLabel isBanned={isBanned} isConfirmed={isConfirmed} />
        </StyledBlock>
    );
};
