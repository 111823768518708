import { useTranslation } from "next-i18next";

import {
    UsersFilterProps,
    FilterState,
    testIds,
} from "./users-filter.interface";
import { RoleFilter } from "components/organisms/filter/role-filter/role-filter.component";
import { FilterAccordion } from "components/organisms/filter/filter-accordion/filter-accordion.component";
import { FilterLayout } from "components/organisms/filter/filter-layout/filter-layout.component";
import { UsersStatusFilter } from "components/organisms/filter/users-status-filter/users-status-filter.component";
import { useFilter } from "lib/hooks/use-filter/use-filter";

export const UsersFilter = ({
    onChange,
    onCloseFilter,
    value,
}: UsersFilterProps) => {
    const { t } = useTranslation("users");

    const {
        filterState,
        setFilterStateByKey,
        totalActiveFilters,
        isDirty,
        reset,
        resetByKey,
        submit,
    } = useFilter<FilterState>(value, onChange);

    return (
        <FilterLayout
            onSubmit={submit}
            onReset={reset}
            onCloseFilter={onCloseFilter}
            isDirty={isDirty}
            totalActiveFilters={totalActiveFilters}
            testId={testIds.usersFilterLayout}
        >
            <FilterAccordion
                reset={resetByKey("roles")}
                title={t("title.roles", "Roles")}
                totalActiveFilters={value?.roles?.length}
                testId={testIds.roleFilter}
            >
                <RoleFilter
                    onChange={setFilterStateByKey("roles")}
                    value={filterState?.roles}
                    testId={testIds.roleCheckbox}
                />
            </FilterAccordion>
            <FilterAccordion
                reset={resetByKey("status")}
                title={t("title.status", "Status")}
                totalActiveFilters={value?.status?.length}
                testId={testIds.statusFilter}
            >
                <UsersStatusFilter
                    onChange={setFilterStateByKey("status")}
                    value={filterState?.status}
                    testId={testIds.statusCheckbox}
                />
            </FilterAccordion>
        </FilterLayout>
    );
};
