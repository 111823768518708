export type CreateNewUserModalProps = {
    opened: boolean;
    onClose: () => void;
};

export const CREATE_USER_SUCCESS_MESSAGE_KEY = "toast.successCreateNewUser";
export const CREATE_USER_SUCCESS_MESSAGE =
    "Registration link was sent to the email.";

export const UPDATE_USER_ACCESS_SUCCESS_MESSAGE_KEY =
    "toast.successAssignedAccesses";
export const UPDATE_USER_ACCESS_SUCCESS_MESSAGE =
    "Accesses have been assigned.";

export const CREATE_USER_ERROR_MESSAGE_KEY = "toast.errorCreateNewUser";
export const CREATE_USER_ERROR_MESSAGE =
    "There was an error. Registration link was not sent.";

export const testIds = {
    createUserModal: "create-user-modal",
    createUserSuccessToast: "create-user-success-toast",
    createUserErrorToast: "create-user-error-toast",
    createUserModalSubmitButton: "create-user-modal-submit-button",
    createUserModalCancelButton: "create-user-modal-cancel-button",
    updateUserAccessErrorToast: "update-user-access-toast",
    generalInformationStepButton: "general-information-step-button",
};
