import styled from "styled-components";

import { breakpoints } from "lib/breakpoints";

export const StyledUsersActionsBlockBody = styled.div`
    display: flex;
    align-items: center;
    gap: 15px;
    padding: 10px 20px 0;

    @media (max-width: ${`${breakpoints.xl}px`}) {
        display: none;
    }
`;

export const StyledMobileSearchInputBlock = styled.div`
    width: 100%;
    display: none;
    padding: 16px;

    @media (max-width: ${`${breakpoints.sm}px`}) {
        display: block;
    }
`;
