import styled from "styled-components";

import { colors } from "lib/palette";
import { Text } from "components/atoms/text/text.component";

export const StyledBlock = styled.div`
    width: 100%;
    padding: 14px 16px;
    background: ${colors.white};
    border: 1px solid ${colors.blue_3};
    border-radius: 5px;
    display: grid;
    grid-template-columns: 36px 1fr auto;
    align-items: center;
    gap: 10px;
`;

export const StyledInfoBlock = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2px;
`;

export const StyledText = styled(Text)`
    word-break: break-word;
`;

export const StyledRole = styled.p`
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: ${colors.black_2};
`;
