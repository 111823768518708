import React, { useEffect, useState } from "react";

import { Tooltip } from "components/atoms/tooltip/tooltip.component";
import {
    SortingButtonProps,
    SortingOrder,
    testIds,
} from "./sorting-button.interface";
import {
    StyledContent,
    StyledOrderBtn,
    StyledTitle,
    StyledTitleAndOrderSvg,
} from "./sorting-button.styled";
import { Icon } from "components/molecules/icon/icon.component";
import { Icons } from "components/molecules/icon/icon.interface";
import { createDataTestId } from "lib/utils/create-dynamic-testid";

export const SortingButton = ({
    name,
    label,
    filterBy,
    filterInfo,
    info,
}: SortingButtonProps) => {
    const [order, setOrder] = useState<SortingOrder.ASC | SortingOrder.DESC>(
        filterInfo.name === name ? filterInfo.order : SortingOrder.ASC,
    );

    useEffect(() => {
        setOrder(
            filterInfo.name === name ? filterInfo.order : SortingOrder.ASC,
        );
    }, [filterInfo, name]);

    const orderByColumn = () => {
        filterBy({
            name,
            order:
                order === SortingOrder.ASC
                    ? SortingOrder.DESC
                    : SortingOrder.ASC,
        });
    };

    return (
        <StyledContent
            data-testid={createDataTestId(name, testIds.sortingButton)}
        >
            <StyledTitleAndOrderSvg onClick={orderByColumn}>
                <StyledTitle>{label}</StyledTitle>
                <StyledOrderBtn order={order}>
                    <Icon name={Icons.upArrowSvg} />
                </StyledOrderBtn>
            </StyledTitleAndOrderSvg>
            {info && <Tooltip message={info} position="bottom" />}
        </StyledContent>
    );
};
