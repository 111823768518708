import { AuthService } from "lib/api/api";
import { V1ResendTokenResponse } from "lib/api/common/data-contracts";
import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { HttpErrorType } from "../types";

type Options = UseMutationOptions<V1ResendTokenResponse, HttpErrorType, string>;

export function useResendTokenMutation(options?: Options) {
    return useMutation(async (args) => {
        return (await AuthService.authServiceResendToken(args, {})).data;
    }, options);
}
