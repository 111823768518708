export type UserInfoModalProps = {
    userId: string;
    opened: boolean;
    onClose: () => void;
};

export const testIds = {
    userInfoModalBody: "user-info-modal-body",
    userInfoModalBackButton: "user-info-modal-back-button",
};
