import { AggregateService } from "lib/api/api";
import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import {
    V1CreateUserResponse,
    V1CreateUserRequest,
} from "../common/data-contracts";
import { HttpErrorType } from "../types";

type Options = UseMutationOptions<
    V1CreateUserResponse,
    HttpErrorType,
    V1CreateUserRequest
>;

export function useCreateUserMutation(options?: Options) {
    return useMutation(async (args: V1CreateUserRequest) => {
        return (await AggregateService.uiBackendServiceCreateUser(args)).data;
    }, options);
}
