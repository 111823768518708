import { useTranslation } from "next-i18next";

import { Button } from "components/atoms/button/button.component";
import { SearchInput } from "components/molecules/search-input/search-input.component";
import {
    UsersActionsBlockProps,
    testIds,
} from "./users-actions-block.interface";
import {
    StyledUsersActionsBlockBody,
    StyledMobileSearchInputBlock,
} from "./users-actions-block.styled";

export const UsersActionsBlock = ({
    searchInputValue,
    setSearchInputValue,
    openNewUserModal,
}: UsersActionsBlockProps) => {
    const { t } = useTranslation("users");

    return (
        <>
            <StyledUsersActionsBlockBody>
                <Button
                    showTitle
                    width={115}
                    onClick={openNewUserModal}
                    data-testid={testIds.createUserButton}
                >
                    {t("button.createUser", "Create user")}
                </Button>
                <SearchInput
                    value={searchInputValue}
                    placeholder={t("placeholder.searchUser", "Search user")}
                    setValue={setSearchInputValue}
                    testId={testIds.userSearch}
                />
            </StyledUsersActionsBlockBody>
            <StyledMobileSearchInputBlock>
                <SearchInput
                    value={searchInputValue}
                    placeholder={t("placeholder.searchUser", "Search user")}
                    setValue={setSearchInputValue}
                    testId={testIds.mobileUserSearch}
                />
            </StyledMobileSearchInputBlock>
        </>
    );
};
