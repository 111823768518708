import { DYNAMIC_TESTID_NORMALIZE_REGEX } from "lib/constants/regex";

export function createDataTestId(
    testId: string | number | undefined,
    prefix: string,
) {
    if (testId === undefined) {
        return prefix;
    }

    const normalizedTestId = String(testId)
        .replace(DYNAMIC_TESTID_NORMALIZE_REGEX, "-")
        .toLowerCase();

    return `${prefix}-${normalizedTestId}`;
}
