import React from "react";
import { StyledHeaderBlock } from "./tablet-content-header.styled";

type Props = {
    children: React.ReactNode;
};

export const TabletContentHeader = ({ children }: Props) => {
    return <StyledHeaderBlock>{children}</StyledHeaderBlock>;
};
