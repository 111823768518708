export type DeleteNotConfirmedUserModalProps = {
    userInfo?: {
        id: string;
        username: string;
    };
    opened: boolean;
    onClose: () => void;
};

export const SUCCESS_TOAST_MESSAGE = "The user was deleted";
export const SUCCESS_TOAST_KEY = "toast.successDeleteUser";
export const ERROR_TOAST_MESSAGE =
    "There was an error. The user was not deleted.";
export const ERROR_TOAST_KEY = "toast.errorDeleteUser";

export const DELETE_USER_KEY = "description.deleteUser";
export const DELETE_USER_MESSAGE =
    "Are you sure you want to delete the user {{username}}?";
export const TITLE_MESSAGE = "Attention";
export const TITLE_KEY = "title.attention";
export const OK_BUTTON_MESSAGE = "Delete";
export const OK_BUTTON_KEY = "button.delete";
export const CANCEL_BUTTON_MESSAGE = "Cancel";
export const CANCEL_BUTTON_KEY = "button.cancel";

export const testIds = {
    okButtonTestId: "delete-button-test-id",
    cancelButtonTestId: "cancel-button-test-id",
    successToast: "toast-success-user-was-deleted",
    errorToast: "toast-error-user-was-deleted",
    deleteNotConfirmedUserModal: "delete-not-confirmed-user-modal",
};
