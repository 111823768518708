export type Configure2FAModalProps = {
    opened: boolean;
    userId: string;
    onClose: () => void;
};

export const testIds = {
    configure2FaModal: "configure-2fa-modal",
    configure2FaModalCancelButton: "configure-2fa-modal-cancel-button",
};
