import { useMediaQuery } from "@mantine/hooks";
import { useTranslation } from "next-i18next";

import { Button } from "components/atoms/button/button.component";
import { Footer } from "components/atoms/footer/footer.component";
import { AdaptiveModal } from "components/molecules/modal/components/adaptive-modal/adaptive-modal.component";
import { ModalDesktopHeader } from "components/molecules/modal/components/modal-desktop-header/modal-desktop-header.component";
import { breakpoints } from "lib/breakpoints";
import { ModalContent } from "./components/modal-content/modal-content.component";
import {
    Configure2FAModalProps,
    testIds,
} from "./configure-2FA-modal.interface";
import { StyledBody } from "./configure-2FA-modal.styled";

export const Configure2FAModal = ({
    opened,
    userId,
    onClose,
}: Configure2FAModalProps) => {
    const { t } = useTranslation("users");

    const isMobile = useMediaQuery(`(max-width: ${`${breakpoints.sm}px`})`);

    return (
        <AdaptiveModal
            testId={testIds.configure2FaModal}
            opened={opened}
            onClose={onClose}
            height={441}
        >
            <StyledBody>
                <ModalDesktopHeader
                    onClose={onClose}
                    title={t("title.configure2FA", "Configure 2FA")}
                />
                {opened && <ModalContent userId={userId} />}
                {isMobile && (
                    <Footer>
                        <Button
                            showTitle
                            width={87}
                            variant="outlined"
                            onClick={onClose}
                            data-testid={testIds.configure2FaModalCancelButton}
                        >
                            {t("button.cancel", "Cancel")}
                        </Button>
                    </Footer>
                )}
            </StyledBody>
        </AdaptiveModal>
    );
};
