import { useTranslation } from "next-i18next";

import { CheckboxGroup } from "components/organisms/filter/checkbox-group/checkbox-group.component";
import { ACTIVE, INACTIVE, WAITING } from "lib/constants/status";
import { UsersStatusFilterProps } from "./users-status-filter.interface";

export const UsersStatusFilter = (props: UsersStatusFilterProps) => {
    const { t } = useTranslation("common");
    const FILTER_OPTIONS = [
        {
            label: t("userStatusFilter.active", "Active"),
            value: ACTIVE,
        },
        {
            label: t("userStatusFilter.inactive", "Inactive"),
            value: INACTIVE,
        },
        {
            label: t("userStatusFilter.waiting", "Waiting"),
            value: WAITING,
        },
    ];

    return <CheckboxGroup {...props} options={FILTER_OPTIONS} />;
};
