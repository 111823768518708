import { useMemo } from "react";
import { useTranslation } from "next-i18next";

import { getUsername } from "lib/utils/entities/users/get-username.utilit";
import { Dropdown } from "components/atoms/dropdown/dropdown.component";
import { zIndexes } from "lib/z-indexes";
import { StyledModalContent, StyledRoleLabel } from "./modal-content.styled";
import { ModalMobileHeader } from "components/molecules/modal/components/modal-mobile-header/modal-mobile-header.component";
import { useGetRolesQuery } from "lib/api/hooks/use-get-roles-query.hook";
import { UserCard } from "components/entities/users/organisms/user-card/user-card.component";
import { FallbackQuery } from "components/organisms/fallback-query/fallback-query.component";
import {
    NOT_ASSIGNED_SUB_ROLE_LABEL,
    NOT_ASSIGNED_SUB_ROLE_OPTION,
    NOT_ASSIGNED_SUB_ROLE_VALUE,
} from "lib/constants/not-assigned-sub-role";
import { ModalContentProps, testIds } from "./modal-content.interface";

export const ModalContent = ({
    title,
    user,
    onUserRoleChange,
}: ModalContentProps) => {
    const { t } = useTranslation("users");

    const rolesQuery = useGetRolesQuery();

    const userRole = useMemo(() => {
        if (!user.sub_role_id) {
            return NOT_ASSIGNED_SUB_ROLE_LABEL;
        }
        return user.sub_role_title;
    }, [user.sub_role_id, user.sub_role_title]);

    const mappedRoles = useMemo(
        () =>
            [NOT_ASSIGNED_SUB_ROLE_OPTION].concat(
                rolesQuery.data?.map((role) => ({
                    value: role.id,
                    label: role.title,
                    "data-testid": `${role.id}-${testIds.option}`,
                })) || [],
            ),
        [rolesQuery.data],
    );

    return (
        <FallbackQuery queries={[rolesQuery]}>
            <ModalMobileHeader title={title} />
            <StyledModalContent>
                <UserCard
                    username={getUsername(user)}
                    isBanned={!!user.is_banned}
                    isConfirmed={!!user.is_confirmed}
                    roleTitle={userRole}
                    // TODO: avatar will be added future
                    avatarImgUrl={undefined}
                />

                <StyledRoleLabel>
                    {t("label.newUserRole", "New user role")}
                </StyledRoleLabel>
                <Dropdown
                    name="vendor"
                    defaultValue={
                        user.sub_role_id || NOT_ASSIGNED_SUB_ROLE_VALUE
                    }
                    data={mappedRoles}
                    onChange={(val) => onUserRoleChange(val as string)}
                    withinPortal
                    zIndex={zIndexes.portalDropdownInModal}
                    testId={testIds.chooseRoleDropdown}
                />
            </StyledModalContent>
        </FallbackQuery>
    );
};
