import { useTranslation } from "next-i18next";

import { Button } from "components/atoms/button/button.component";
import { Icon } from "components/molecules/icon/icon.component";
import { Icons } from "components/molecules/icon/icon.interface";
import { useGetUserByIdQuery } from "lib/api/hooks/use-get-user-query.hook";
import { useReset2FAMutation } from "lib/api/hooks/use-reset-2fa-mutation.hook";
import { useUpdate2FAMandatoryStatusMutation } from "lib/api/hooks/use-update-2fa-mandatory-status-mutation.hook";
import { successToast } from "lib/utils/toast";
import { UserCard } from "../../../user-card/user-card.component";
import { generateUsername } from "../../../user-permissions/user-permissions.utilit";
import {
    CHANGE_2FA_MANDATORY_STATUS_SUCCESS_TOAST_KEY,
    CHANGE_2FA_MANDATORY_STATUS_SUCCESS_TOAST_MESSAGE,
    DISABLE_2FA,
    ModalContentProps,
    RESET_2FA_SUCCESS_TOAST_KEY,
    RESET_2FA_SUCCESS_TOAST_MESSAGE,
    testIds,
} from "./modal-content.interface";
import {
    Styled2FAStatus,
    Styled2FAStatusBlock,
    StyledActionsBlock,
    StyledContent,
    StyledDescription,
    StyledHeading,
    StyledSubBlock,
} from "./modal-content.styled";
import { showErrorToast } from "lib/utils/show-error-toast";
import { FallbackQuery } from "components/organisms/fallback-query/fallback-query.component";

export const ModalContent = ({ userId }: ModalContentProps) => {
    const { t } = useTranslation("users");

    const userQuery = useGetUserByIdQuery(userId);

    const { mutate: reset2FA, isLoading: isResetting } = useReset2FAMutation({
        onSuccess: async () => {
            successToast(
                t(RESET_2FA_SUCCESS_TOAST_KEY, RESET_2FA_SUCCESS_TOAST_MESSAGE),
            );
            await userQuery.refetch();
        },
        onError: (error) => {
            showErrorToast(error);
        },
    });

    const {
        mutate: update2FAMandatoryStatus,
        isLoading: is2FAMandatoryStatusUpdating,
    } = useUpdate2FAMandatoryStatusMutation({
        onSuccess: async () => {
            successToast(
                t(
                    CHANGE_2FA_MANDATORY_STATUS_SUCCESS_TOAST_KEY,
                    CHANGE_2FA_MANDATORY_STATUS_SUCCESS_TOAST_MESSAGE,
                ),
            );
            await userQuery.refetch();
        },
        onError: (error) => {
            showErrorToast(error);
        },
    });

    const handleReset2FA = () => {
        if (userQuery.data?.user?.general.is_2fa_mandatory) {
            reset2FA(userId);
        }
    };
    const handleUpdate2FAMandatoryStatus = () => {
        update2FAMandatoryStatus({
            user_id: userId,
            is_2fa_mandatory: !userQuery.data?.user?.general.is_2fa_mandatory,
        });
    };

    return (
        <FallbackQuery queries={[userQuery]}>
            {userQuery.data && (
                <StyledContent>
                    <UserCard
                        isBanned={!!userQuery.data?.user?.general.is_banned}
                        isConfirmed={
                            !!userQuery.data?.user?.general.is_confirmed
                        }
                        username={generateUsername(userQuery.data)}
                        // TODO: avatar will be added future
                        avatarImgUrl={undefined}
                        roleTitle={userQuery.data?.user?.general.sub_role_title}
                    />
                    <StyledSubBlock
                        disabled={
                            !userQuery.data?.user?.general.is_2fa_mandatory
                        }
                    >
                        <StyledHeading>
                            {t("title.reset2FA", "Reset 2FA")}
                        </StyledHeading>
                        <StyledDescription>
                            {t(
                                "description.reset2FA",
                                "After resetting 2FA the user needs to set up it again when log in.",
                            )}
                        </StyledDescription>
                        <StyledActionsBlock>
                            <Button
                                showTitle
                                width={106}
                                disabled={
                                    !userQuery.data.user?.general.otp_activated
                                }
                                onClick={handleReset2FA}
                                loading={isResetting}
                                data-testid={testIds.reset2faButton}
                            >
                                {t("button.reset2FA", "Reset 2FA")}
                            </Button>
                            <Styled2FAStatusBlock>
                                <Icon
                                    name={
                                        !userQuery.data.user?.general
                                            .otp_activated ||
                                        !userQuery.data?.user?.general
                                            .is_2fa_mandatory
                                            ? Icons.activeStatusOffSvg
                                            : Icons.activeStatusOnSvg
                                    }
                                />
                                <Styled2FAStatus>
                                    {!userQuery.data.user?.general
                                        .otp_activated ||
                                    !userQuery.data?.user?.general
                                        .is_2fa_mandatory
                                        ? t("label.2faInactive", "2FA inactive")
                                        : t("label.2faActive", "2FA active")}
                                </Styled2FAStatus>
                            </Styled2FAStatusBlock>
                        </StyledActionsBlock>
                    </StyledSubBlock>
                    <StyledSubBlock>
                        <StyledHeading>
                            {t("title.disable2FA", "Disable 2FA")}
                        </StyledHeading>
                        <StyledDescription>
                            {t("description.disable2FA", DISABLE_2FA)}
                        </StyledDescription>
                        <Button
                            showTitle
                            width={117}
                            onClick={handleUpdate2FAMandatoryStatus}
                            loading={is2FAMandatoryStatusUpdating}
                            data-testid={testIds.change2faMandatoryStatusButton}
                        >
                            {userQuery.data?.user?.general.is_2fa_mandatory
                                ? t("button.disable2FA", "Disable 2FA")
                                : t("button.enable2FA", "Enable 2FA")}
                        </Button>
                    </StyledSubBlock>
                </StyledContent>
            )}
        </FallbackQuery>
    );
};
