import { useMemo, useState } from "react";
import { useMediaQuery } from "@mantine/hooks";
import { useTranslation } from "next-i18next";

import { Button } from "components/atoms/button/button.component";
import { Icon } from "components/molecules/icon/icon.component";
import { Icons } from "components/molecules/icon/icon.interface";
import { useGetUserAccessQuery } from "lib/api/hooks/use-get-user-access-query.hook";
import { useUpdateUserAccessMutation } from "lib/api/hooks/use-update-user-access-mutation.hook";
import { breakpoints } from "lib/breakpoints";
import { useUserType } from "lib/hooks/use-user-type.hook";
import {
    filterCompanies,
    generateNewAccess,
    getCompanyForGenerateNewAccess,
} from "lib/utils/entities/companies/companies-access.utilit";
import { CompaniesCheckboxList } from "../companies-checkbox-list/companies-checkbox-list.component";
import { CompaniesCheckboxTree } from "../companies-checkbox-tree/companies-checkbox-tree.component";
import {
    CompanyAccessFormProps,
    testIds,
} from "./company-access-form.interface";
import {
    StyledFooter,
    StyledInfo,
    StyledLoaderBlock,
    StyledContent,
} from "./company-access-form.styled";
import { UserType } from "lib/constants/user-type";
import { useGetCompaniesQuery } from "lib/api/hooks/use-get-companies-query.hook";
import { getCompaniesIds } from "lib/utils/entities/companies/get-companies-ids";
import { Spinner } from "components/atoms/spinner/spinner.component";

export const CompanyAccessForm = ({
    userId,
    onCancel,
    onSuccess,
    onError,
}: CompanyAccessFormProps) => {
    const { t } = useTranslation("users");

    const userType = useUserType();
    const isMobile = useMediaQuery(`(max-width: ${`${breakpoints.sm}px`})`);

    const { data, isFetching } = useGetUserAccessQuery(userId, {
        retry: false,
        refetchOnWindowFocus: false,
        select: (companiesData) => {
            return {
                ...companiesData,
                available_b2b_companies: filterCompanies(
                    userType,
                    companiesData,
                    userId,
                ),
            };
        },
        onSuccess: (companiesData) => {
            if (companiesData?.available_b2b_companies) {
                setCompanies(companiesData?.available_b2b_companies);
            }
        },
    });
    const { mutate, isLoading: isMutating } = useUpdateUserAccessMutation({
        onSuccess,
        onError,
    });
    const [companies, setCompanies] = useState(
        data?.available_b2b_companies || [],
    );

    const companiesQuery = useGetCompaniesQuery();

    const updatePermissionsHandler = () => {
        const newPermissionsData = generateNewAccess(
            (companies || []).map(getCompanyForGenerateNewAccess),
            (companiesQuery.data?.available_b2b_companies || []).map(
                (item) => item.general.id,
            ),
        );
        if (
            newPermissionsData?.grant?.length ||
            newPermissionsData?.revoke?.length
        ) {
            mutate({
                userId,
                body: newPermissionsData,
            });
        } else {
            onSuccess();
        }
    };

    const availableCompanies = useMemo(
        () => getCompaniesIds(companiesQuery.data),
        [companiesQuery.data],
    );

    return (
        <StyledContent data-testid={testIds.companyAccessForm}>
            <StyledInfo>
                {t(
                    "description.selectCompanies",
                    "Select the companies that the user can manage.",
                )}
            </StyledInfo>
            {isFetching ? (
                <StyledLoaderBlock data-testid={testIds.loaderIcon}>
                    <Spinner>
                        <Icon name={Icons.loaderSvg} />
                    </Spinner>
                </StyledLoaderBlock>
            ) : (
                <>
                    {userType === UserType.B2B_USER ? (
                        <CompaniesCheckboxTree
                            items={companies}
                            updateItems={setCompanies}
                            availableItems={availableCompanies}
                        />
                    ) : (
                        <CompaniesCheckboxList
                            items={companies}
                            updateItems={setCompanies}
                        />
                    )}
                </>
            )}

            <StyledFooter>
                <Button
                    showTitle
                    width={138}
                    loading={isMutating}
                    type="submit"
                    onClick={updatePermissionsHandler}
                    data-testid={testIds.giveAccessButton}
                >
                    {t("button.provideAccess", "Provide access")}
                </Button>
                {isMobile && (
                    <Button
                        showTitle
                        width={87}
                        onClick={onCancel}
                        variant="outlined"
                        data-testid={testIds.cancelGiveAccessModalButton}
                    >
                        {t("button.cancel", "Cancel")}
                    </Button>
                )}
            </StyledFooter>
        </StyledContent>
    );
};
