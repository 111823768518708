import { useMutation, UseMutationOptions } from "@tanstack/react-query";

import { AuthService } from "../api";
import { V1Change2FAResponse } from "../common/data-contracts";
import { HttpErrorType } from "../types";

type Options = UseMutationOptions<
    V1Change2FAResponse,
    HttpErrorType,
    { user_id: string; is_2fa_mandatory: boolean }
>;

export function useUpdate2FAMandatoryStatusMutation(options?: Options) {
    return useMutation(async ({ user_id, is_2fa_mandatory }) => {
        return (
            await AuthService.authServiceChange2Fa(user_id, {
                is_2fa_mandatory,
            })
        ).data;
    }, options);
}
