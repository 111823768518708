export type UserStatusWarningModalProps = {
    isOpen: boolean;
    close: () => void;
    userData: {
        username: string;
        id: string;
        isBanned: boolean;
        isOpen: boolean;
    };
};

export const testIds = {
    deactivateUserButton: "deactivate-user-button",
    activateUserButton: "activate-user-button",
    changeUserStatusModalCancelButton: "change-user-status-modal-cancel-button",
    activateUserSuccessToast: "activate-user-success-toast",
    activateUserErrorToast: "activate-user-error-toast",
    deactivateUserSuccessToast: "deactivate-user-success-toast",
    deactivateUserErrorToast: "deactivate-user-error-toast",
};
