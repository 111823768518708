import styled from "styled-components";

import { breakpoints } from "lib/breakpoints";

export const StyledUsersContent = styled.div`
    padding: 10px 20px;

    @media (max-width: ${`${breakpoints.sm}px`}) {
        padding: 0 16px;
    }

    & > div > table {
        th {
            padding: 12px 15px;

            &:first-child {
                width: 50px;
                padding-left: 20px;
                padding-right: 0;
            }
        }

        td {
            padding: 8px 15px;
            line-height: normal;

            &:nth-child(2),
            &:nth-child(3) {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 200px;
            }

            &:first-child {
                width: 50px !important;
                padding-left: 16px;
                padding-right: 0;
            }

            @media (max-width: ${`${breakpoints.sm}px`}) {
                &:nth-child(3) {
                    text-align: right;
                    padding-left: 0;
                }

                &:nth-child(2) {
                    padding: 8px 5px 8px 10px;
                    max-width: 110px;
                    width: auto;

                    div {
                        width: 100%;

                        p {
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            max-width: 100%;
                        }
                    }
                }
            }
        }
    }
`;
