import { useRouter } from "next/router";
import { useRecoilValue } from "recoil";
import { useTranslation } from "next-i18next";

import { Menu } from "components/atoms/menu/menu.component";
import { userState } from "lib/recoil/atoms/user.atom";
import { getUsername } from "lib/utils/entities/users/get-username.utilit";
import { useResendTokenMutation } from "lib/api/hooks/use-resend-token-mutation";
import { paths } from "lib/constants/paths";
import { errorToast, successToast } from "lib/utils/toast";
import { useCheckUserPermissions } from "lib/hooks/use-check-user-permissions.hook";
import { Permission } from "lib/constants/permissions";
import { ActionButtonWrapper } from "components/atoms/menu/menu.styled";
import { UserType } from "lib/constants/user-type";
import {
    REGISTRATION_LINK_ERROR_TOAST_MESSAGE_KEY,
    REGISTRATION_LINK_SUCCESS_TOAST_MESSAGE_KEY,
    testIds,
    UserInfoMenuButtonProps,
} from "./user-info-menu-button.interface";

export const UserInfoMenuButton = ({
    userInfo,
    openUserInfoModal,
    setUserId,
    setDeleteUserInfo,
    setChangeRoleData,
    setUserStatusModalData,
    setReset2FAModalData,
}: UserInfoMenuButtonProps) => {
    const { t } = useTranslation("users");

    const router = useRouter();
    const { checkSome } = useCheckUserPermissions();

    const user = useRecoilValue(userState);
    const handleShowUserInfo = () => {
        setUserId(userInfo.id);
        openUserInfoModal(true);
    };

    const handleRedirectToUserAccessesPage = async () => {
        await router.push({
            pathname: paths.USERS_PERMISSIONS,
            query: { userId: userInfo?.id },
        });
    };

    const { mutate } = useResendTokenMutation({
        onSuccess: () => {
            successToast(
                t(
                    REGISTRATION_LINK_SUCCESS_TOAST_MESSAGE_KEY,
                    "Registration link was sent to the email",
                ),
                { className: testIds.resendTokenSuccessToast },
            );
        },
        onError: () => {
            errorToast(
                t(
                    REGISTRATION_LINK_ERROR_TOAST_MESSAGE_KEY,
                    "There was an error. Registration link was not sent.",
                ),
                { className: testIds.resendTokenErrorToast },
            );
        },
    });

    const openStatusManagerModal = () => {
        setUserStatusModalData({
            id: userInfo.id,
            isBanned: !!userInfo?.is_banned,
            username: getUsername(userInfo),
            isOpen: true,
        });
    };

    const openDeleteModal = () => {
        setDeleteUserInfo({
            id: userInfo.id,
            username: getUsername(userInfo),
        });
    };

    const isCurrentUser = user?.auth?.user?.id === userInfo.id;

    const menuItems = [
        !userInfo?.is_confirmed && {
            children: t("button.sendInvitations", "Send the invitations again"),
            onClick: () => mutate(userInfo.id),
            testId: `${testIds.sendInvitationsAgain}-${userInfo.id}`,
        },
        !isCurrentUser && {
            children: t("button.changeRole", "Change role"),
            onClick: () => setChangeRoleData(userInfo),
            testId: `${testIds.changeRole}-${userInfo.id}`,
        },
        userInfo?.is_confirmed &&
            !isCurrentUser && {
                children: userInfo.is_banned
                    ? t("button.activate", "Activate")
                    : t("button.deactivate", "Deactivate"),
                onClick: openStatusManagerModal,
                testId: userInfo.is_banned
                    ? `${testIds.activate}-${userInfo.id}`
                    : `${testIds.deactivate}-${userInfo.id}`,
            },
        !isCurrentUser &&
            user?.auth.user.role !== UserType.PROVIDER && {
                children: t("button.setUpAccesses", "Set up accesses"),
                onClick: handleRedirectToUserAccessesPage,
                testId: `${testIds.setUpAccess}-${userInfo.id}`,
            },
        !userInfo?.is_confirmed && {
            children: t("button.delete", "Delete"),
            onClick: openDeleteModal,
            testId: `${testIds.delete}-${userInfo.id}`,
        },
        {
            children: t("button.userInfo", "User info"),
            onClick: handleShowUserInfo,
            testId: `${testIds.userInfo}-${userInfo.id}`,
        },
        userInfo?.is_confirmed &&
            checkSome([
                Permission.RESET_2FA,
                Permission.ENABLE_DISABLE_2FA,
            ]) && {
                children: t("button.configure2FA", "Configure 2FA"),
                onClick: () => setReset2FAModalData(userInfo.id),
                testId: `${testIds.configure2fa}-${userInfo.id}`,
            },
    ];

    return (
        <ActionButtonWrapper>
            <Menu
                testId={`${testIds.userActionsMenu}-${userInfo.id}`}
                offset={0}
                menuItems={menuItems.filter((i) => i) as []}
            />
        </ActionButtonWrapper>
    );
};
