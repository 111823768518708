import { useState } from "react";

import {
    SortingInfo,
    SortingOrder,
} from "components/molecules/table/components/sorting-button/sorting-button.interface";

export const useSortState = (initialState?: SortingInfo) => {
    const [state, setState] = useState<SortingInfo>(
        initialState || {
            name: "",
            order: SortingOrder.ASC,
        },
    );

    return { sortState: state, setSortState: setState };
};
