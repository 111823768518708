import { NOT_ASSIGNED_SUB_ROLE_LABEL } from "lib/constants/not-assigned-sub-role";

export type RoleFilterProps = {
    onChange: (data: string[]) => void;
    value: string[];
    testId?: string;
};

export const NOT_ASSIGNED_FILTER_OPTION = {
    value: "",
    label: NOT_ASSIGNED_SUB_ROLE_LABEL,
};

export const testIds = {
    roleFilter: "role-filter",
};
