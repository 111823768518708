import { Stepper } from "@mantine/core";
import styled from "styled-components";

import { breakpoints } from "lib/breakpoints";
import { colors } from "lib/palette";

export const StyledStepper = styled(Stepper)`
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1 1 auto;
    overflow: hidden;

    @media (max-width: ${`${breakpoints.sm}px`}) {
        margin-top: 0;
        flex: none;
        overflow: unset;
    }

    .mantine-Stepper-steps {
        width: 100%;
        padding: 20px calc(50% - 135px) 42px calc(50% - 135px);
        background: ${colors.white};
        border-bottom: 1px solid ${colors.blue_3};
        position: relative;

        &::after {
            content: "";
            display: inline-block;
            position: absolute;
            bottom: -10px;
            left: 0;
            width: 100%;
            height: 10px;
            background: linear-gradient(
                0deg,
                rgb(0 56 107 / 3%) 0%,
                rgb(2 57 108 / 0%) 100%
            );
            transform: matrix(1, 0, 0, -1, 0, 0);
        }

        .mantine-Stepper-step {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 10px;
            position: relative;

            .mantine-Stepper-stepIcon {
                border: none !important;

                &[data-completed="true"] {
                    border: 1px solid rgb(23 182 115) !important;
                    background: rgb(23 182 115);
                }

                &[data-progress="true"] {
                    border: 1px solid ${colors.yellow_1} !important;
                    background: ${colors.yellow_1};
                }
            }

            .mantine-Stepper-stepBody {
                margin-left: 0;
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 18px;
                color: ${colors.black_1};
                position: absolute;
                width: max-content;
                bottom: -22px;
            }
        }

        .mantine-Stepper-separator {
            margin: 0;
            background-color: ${colors.black_4};
        }

        @media (max-width: ${`${breakpoints.sm}px`}) {
            position: relative;
            top: 0;
            border-bottom: none;
            padding-top: 15px;
            padding-bottom: 22px;

            &::after {
                display: none;
            }
        }

        @media (width <= 380px) {
            width: 100%;
            padding: 15px calc(50% - 115px) 15px calc(50% - 115px);
        }
    }

    .mantine-Stepper-content {
        & > ul {
            padding-left: 0;
        }

        padding: 20px 0;
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 20px;
        flex: 1 1 auto;
        overflow-y: auto;
        margin-bottom: 60px;

        @media (max-width: ${`${breakpoints.sm}px`}) {
            flex: none;
            margin-bottom: 0;
            overflow-y: unset;
        }
    }

    @media (max-width: ${`${breakpoints.sm}px`}) {
        padding: 0 20px;
    }
`;
