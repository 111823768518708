import styled from "styled-components";

import { Text } from "components/atoms/text/text.component";
import { breakpoints } from "lib/breakpoints";

export const StyledInfoBlock = styled.div`
    display: flex;
    justify-content: center;
    padding: 30px 50px;

    @media (max-width: ${breakpoints.sm}px) {
        padding: 20px;
    }
`;

export const StyledText = styled(Text)`
    max-width: 400px;
`;

export const StyledFormBlock = styled.div`
    padding: 0 50px 30px;

    @media (max-width: ${breakpoints.sm}px) {
        padding: 0 20px 20px;
    }
`;
