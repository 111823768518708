import { TFunction } from "next-i18next";

export const INITIAL_DATE_TRANSLATE_KEY = "common:date.intlDateTime";

export const generateDate = (
    t: TFunction,
    date?: number | string,
    withTime?: boolean,
) => {
    if (!date) {
        return "";
    }

    return t(INITIAL_DATE_TRANSLATE_KEY, {
        val: new Date(date),
        formatParams: {
            val: {
                year: "numeric",
                month: "short",
                day: "numeric",
                ...(withTime ? { hour: "numeric", minute: "numeric" } : {}),
            },
        },
    });
};
