import styled, { css } from "styled-components";

import { breakpoints } from "lib/breakpoints";
import { colors } from "lib/palette";

export const StyledContent = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 20px;
    overflow-y: auto;
    padding: 20px 50px 50px;

    @media (max-width: ${breakpoints.sm}px) {
        padding: 20px;
    }
`;

export const StyledSubBlock = styled.div<{ disabled?: boolean }>`
    ${({ disabled }) =>
        disabled &&
        css`
            pointer-events: none;
            opacity: 0.2;
        `}
`;

export const StyledHeading = styled.h2`
    color: ${colors.black_1};
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 5px;
`;

export const StyledDescription = styled.p`
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: ${colors.black_6};
    margin-bottom: 15px;
`;

export const StyledActionsBlock = styled.div`
    display: flex;
    align-items: center;
    gap: 20px;
`;

export const Styled2FAStatusBlock = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
`;

export const Styled2FAStatus = styled.p`
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: ${colors.black_6};
`;
