import { ReactNode } from "react";

type Row = {
    name: string;
    render: () => ReactNode;
};

type RowInfo = {
    id?: string;
    rawObject?: Record<string, any>;
};

export type TableRow<T extends string> = Record<T, Row> & {
    data?: RowInfo;
    actions?: Row;
};

export type TableColumn<T> = {
    name: T;
    render: () => ReactNode;
};

export type TableProps<T extends string> = {
    data?: TableRow<T>[];
    columns?: TableColumn<T>[];
    onRowClick?: (data: TableRow<T>) => void;
    showHeadInMobile?: boolean;
    activeRowId?: string | null;
    fallbackMessage?: string;
    isLoading?: boolean;
    dataTestId?: string;
};

export type StyledRowProps = {
    isClickable?: boolean;
    activeRowId?: string | null;
    rowId?: string;
};

export type StyledTheadProps = {
    showHeadInMobile?: boolean;
};

export type RowProps = {
    isHaveActions?: boolean;
};

export const TABLE_NO_DATA_KEY = "table.label.noData";

export const testIds = {
    tableLoaderBlock: "table-loader-block",
    tableNoDataBlock: "table-no-data-block",
};
