import { breakpoints } from "lib/breakpoints";
import styled from "styled-components";

export const StyledBody = styled.div`
    width: 560px;
    height: 440px;
    min-height: 100%;
    max-width: 100%;
    max-height: 100%;
    display: flex;
    flex-direction: column;

    @media (max-width: ${breakpoints.sm}px) {
        width: 100%;
        padding-top: 56px;
        padding-bottom: 60px;
    }
`;
