import { useMutation, UseMutationOptions } from "@tanstack/react-query";

import { PermissionsService } from "lib/api/api";
import { Permissionv1EmptyResponse } from "../common/data-contracts";
import { HttpErrorType } from "../types";

type ChangeUserRoleArgs = {
    user_id: string;
    body: {
        sub_role_id?: string;
    };
};

type Options = UseMutationOptions<
    Permissionv1EmptyResponse,
    HttpErrorType,
    ChangeUserRoleArgs
>;

export function useChangeUserRoleMutation(options?: Options) {
    return useMutation(async ({ user_id, body }: ChangeUserRoleArgs) => {
        return (
            await PermissionsService.permissionServiceGrantSubRole(
                user_id,
                body,
            )
        ).data;
    }, options);
}
